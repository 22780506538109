<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="配送员" prop="name">
          <el-input
            size="mini"
            v-model.trim="table.params.name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="食堂名称" prop="store_id">
          <el-select v-model="table.params.store_id" placeholder="请选择" clearable @change="onSearch">
            <el-option v-for="item in storeArr" :label="item.name"  :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 不是地区账号加地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="!city && !province">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:store_list="{row}">
        <span>{{row.store_list.join("、")}}</span>
      </template>
      <template v-slot:area="{row}">
        <span>{{row.province_name}}{{row.city_name}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="handlePwd(row)">修改密码</el-button>
        <el-button type="text" @click="remove(row)" v-if="is_sys == 1">删除</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable" ></edit>

    <!-- 修改密码 -->
    <NewPwd ref="newpwd"></NewPwd>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import NewPwd from './components/newPwd.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Edit,
    NewPwd,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      province: getStorage('province'),
      city: getStorage('city'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      is_sys: this.$store.state.is_sys, // 1:只有超级管理员才有列表的删除按钮
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "配送员", hidden: false },
        { name: "account", label: "联系方式", hidden: false},
        { name: "area", label: "地区", hidden: false},
        { name: "store_list", label: "配送路线", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          account: '',
          area: [],
          province: '',
          city: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      storeArr: [],
    
    }
  },
  created() {
    this.getTable();
    this.getStore();
  },
  methods: {
    getTable() {
       let _area = this.table.params.area;
      if(_area.length == 1) {
        this.table.params.province = _area[0]
      } else if(_area.length == 2) {
        this.table.params.province = _area[0]
        this.table.params.city = _area[1]
      } else {
        this.table.params.province = '';
        this.table.params.city = '';
      }
      this.table.loading = true;
      this.$http.get('/admin/path/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 客户的食堂列表
    getStore() {
      this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
        if(res.code === 1) {
          this.storeArr = res.data.list;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 修改密码
    handlePwd(row) {
      let dom = this.$refs.newpwd
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/path/delete"
      );
    },
  }
}
</script>
